import { createTheme, PaletteMode, ThemeProvider } from "@mui/material";

// const [mode, setMode] = React.useState<'light' | 'dark'>('light');

export const palette = {
  primary: { main: "#24242c" },
  secondary: { main: "#a3956d" },
  light: { main: "#4cdede" },
  background: { paper: "#b7b7b7", default: "#f5f5f5" },
  special: { main: "#d4af37" },
};

function AppThemeProvider({ children, mode }: any) {
  const theme = createTheme({
    // direction: "rtl",
    palette: palette,
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            margin: 2,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: palette.secondary.main,
            margin: 2,
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            marginLeft: "0",
          },
        },
      },
    },
    typography: {
      h1: {
        fontSize: "3.8rem",
        fontWeight: 700,
      },
      h2: {
        fontSize: "2.5rem",
        fontWeight: 700,
      },
      h3: {
        fontSize: "1.6rem",
      },
      h4: {
        fontSize: "1.2rem",
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default AppThemeProvider;

//need to be fixed
