import { Container, Box, Grid } from "@mui/material";
import React from "react";
import CardSkeleton from "../components/general/CardSkeleton";
import SongCard from "../components/general/SongCard";
import { SONGS } from "../data/data";
import Title from "../components/general/Title";
import PageCircle from "../components/general/PageCircle";
import noshemet from "./../images/noshemet.png";
import mizmor from "./../images/mizmor.jpeg";
import cover from "./../images/background.jpeg";

function MySongsPage() {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const pics = [noshemet, noshemet, mizmor];

  return (
    <>
      <Box>
        <Title mainText="מוזיקה מקורית" subText="חומר מקורי של יצירה מוזיקלית" />
        <Container
          sx={{
            margin: "10px auto",
            minHeight: "700px",
          }}
        >
          <Container>
            <Box>
              {loading ? (
                <PageCircle />
              ) : (
                <Grid container spacing={2}>
                  {SONGS &&
                    SONGS.map((card, index) => (
                      <Grid item xs={11} sm={6} md={4} key={card.id}>
                        <SongCard key={card.id} {...card} image={pics[index]} />
                      </Grid>
                    ))}
                </Grid>
              )}
            </Box>
          </Container>
        </Container>
      </Box>
    </>
  );
}

export default MySongsPage;
