import AnimationSharpIcon from "@mui/icons-material/AnimationSharp";
import { useNavigate } from "react-router-dom";
import { palette } from "../plugins/mui";
interface Props {
  size?: any;
}

function AppTitle({ size = "20px" }: Props) {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="logo"
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/");
        }}
      >
        <span style={{ fontSize: size }}>
          <span style={{ color: palette.secondary.main, fontSize: "40px" }}>ש</span>נינ<span>ה</span>
        </span>
      </div>
    </>
  );
}

export default AppTitle;
