// src/components/WhatsAppButton.tsx

import React from "react";
import { IconButton } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

interface WhatsAppButtonProps {
  phoneNumber: string;
}

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ phoneNumber }) => {
  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}`;

    window.open(url, "_blank");
  };

  return (
    <IconButton onClick={handleClick} color="secondary" aria-label="WhatsApp">
      <WhatsAppIcon
        sx={{
          color: "white",
          width: "30px",
          height: "30px",
          "&:hover": {
            color: "black",
          },
        }}
      />
    </IconButton>
  );
};

export default WhatsAppButton;
