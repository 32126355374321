import * as React from "react";
import { Box, Container, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { songCard } from "../services/Interfaces";
import long from "./../images/9afd40_3eff9db53c6b4f8790f0e9f19a8b3a10~mv2.jpg";
import Spots from "../components/general/Spots";
import Title from "../components/general/Title";
import PageCircle from "../components/general/PageCircle";
function HomePage() {
  const [loading, setLoading] = React.useState(true);
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <PageCircle />
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              height: "690px",
              background: "linear-gradient(to right, #595959 4%,#040404 2%, #040404 50%)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <Box
              className="design-box"
              width={mdAndUp ? "25vw" : "56vw"}
              // height={"70vh"}
              position={"absolute"}
              left={"40%"}
              top={"20%"}
            >
              <Title mainText="הופעות לארועים" />
              <Typography textAlign={"center"} margin={2} color={"white"} variant="h5">
                הופעת אמנית - חומר מקורי.
              </Typography>
              <Typography textAlign={"center"} margin={2} color={"white"} variant="h5">
                ערבי שירה בציבור וחיבור לבבות.
              </Typography>
              <Typography textAlign={"center"} margin={2} color={"white"} variant="h5">
                מופעי קאבר ומחווה ליוצרים מהארץ ומחו"ל.
              </Typography>
              <Typography textAlign={"center"} margin={2} color={"white"} variant="h5">
                קבלות פנים לארועים.
              </Typography>
            </Box>
            <Spots />
            <Spots width="10px" height="10px" y="150px" x="10vw" />
            <Spots width="15px" height="15px" y="240px" x="20vw" />
            <Spots width="15px" height="18px" y="160px" x="260px" />
            <Box
              component="img"
              sx={{
                maxWidth: { xs: 330, md: 390 },
              }}
              alt=""
              src={long}
            />
          </Box>
          <Box />
        </>
      )}
    </>
  );
}

export default HomePage;
