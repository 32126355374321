import { IconButton } from "@mui/material";
import { ReactComponent as SpotifyIcon } from "../../icons/spotify.svg";
import { ReactComponent as YouTubeIcon } from "../../icons/youtube.svg";
import { ReactComponent as AppleIcon } from "../../icons/apple.svg";
import { palette } from "../../plugins/mui";

interface Props {
  iconName: string;
  size?: string;
  link: string;
  color?: string;
}

function IconButtonMedia({ iconName, size = "28px", link, color = palette.secondary.main }: Props) {
  return (
    <IconButton href={link} target="_blank">
      {iconName === "spotify" ? (
        <SpotifyIcon style={{ height: size, width: size, color: color }} />
      ) : iconName === "youtube" ? (
        <YouTubeIcon className="you-tube" style={{ height: size, width: size, color: color }} />
      ) : (
        <AppleIcon style={{ height: size, width: size, color: color }} />
      )}
    </IconButton>
  );
}

export default IconButtonMedia;
