import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import AppTitle from "../AppTitle";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useNavigate } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";

function DrawerTabs() {
  const openArr = [
    { title: "בית", link: "/", icon: <HomeIcon /> },
    { title: "אודות", link: "/about", icon: <InfoIcon /> },
    { title: "צור קשר", link: "/contact", icon: <CallIcon /> },
    { title: "שירים", link: "/my-songs", icon: <LibraryMusicIcon /> },
  ];

  const navigate = useNavigate();

  return (
    <>
      <div>
        <Typography variant="h2" margin={1}>
          <AppTitle />
        </Typography>
        {openArr.map((tab) => (
          <Box key={tab.title}>
            <Divider />
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(tab.link);
                  }}
                >
                  <ListItemIcon>{tab.icon}</ListItemIcon>
                  <ListItemText secondary={tab.title} />
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
          </Box>
        ))}
      </div>
    </>
  );
}

export default DrawerTabs;
