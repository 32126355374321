import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Title from "../components/general/Title";
import shaniGuitar from "./../images/shaniGuitar.jpg";
import React from "react";
import PageCircle from "../components/general/PageCircle";

function AboutPage() {
  const [loading, setLoading] = React.useState(true);
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <PageCircle />
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              height: "690px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <Box
              marginTop={4}
              width={mdAndUp ? "55vw" : "56vw"}
              height={"50vh"}
              position={"absolute"}
              left={mdAndUp ? "50%" : "30%"}
              top={"20%"}
            >
              <Title
                mainText="אודות"
                subText="אני שנינה נוי דבוש
            זמרת יוצרת"
              ></Title>
              <Box sx={{ width: mdAndUp ? "30vw" : "70vw", margin: "auto auto" }}>
                <Typography align="inherit" fontSize={18} color={"white"} textAlign={"center"} gutterBottom paragraph margin={2}>
                  מאז ומתמיד המוזיקה היתה חלום עבורי, כתבתי למגירה, התביישתי נורא, ומשפט אחד תמיד דחף אותי לא לוותר : "אין גיל
                  לחלומות" וחלומות יש להגשים" זה המשפט שהפך למוטו שלי בחיים. כולנו נולדנו עם דחף נשמתי וחיבור לא מוסבר למשהו,
                  כמיהה למשהו חזק מאיתנו. שמחה להיחשף עם הנשמה לאור ולאפשר לכם להציץ ולמצוא רגשות משותפים ביצירות ובחלומות שלי .
                </Typography>
                <Typography align="inherit" fontSize={18} color={"white"} textAlign={"center"} gutterBottom paragraph margin={2}>
                  כולנו נולדנו עם דחף נשמתי וחיבור לא מוסבר למשהו, כמיהה למשהו חזק מאיתנו. שמחה להיחשף עם הנשמה לאור ולאפשר לכם
                  להציץ ולמצוא רגשות משותפים ביצירות ובחלומות שלי
                </Typography>
              </Box>
            </Box>

            <Box
              component="img"
              sx={{
                borderRadius: "0% 100% 0% 30% / 100% 98% 16% 20%",

                filter: mdAndUp ? "opacity(1)" : "opacity(0.5)",
              }}
              alt=""
              src={shaniGuitar}
            />
          </Box>
          <Box />
        </>
      )}
    </>
  );
}

export default AboutPage;
