import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ReactComponent as IconSpotify } from "../../icons/spotify.svg";
import { songCard } from "../../services/Interfaces";
// modal

import { palette } from "../../plugins/mui";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import IconButtonMedia from "./IconButtonMedia";

interface Props extends songCard {
  alt?: string;
}

function SongCard({ _id, title, subTitle, image, alt, spotify, youTube, appleMusic }: Props) {
  const navigate = useNavigate();

  // General functions

  return (
    <>
      <Card
        sx={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          boxShadow: " 0px 8px 16px 0px rgba(0,0,0,0.5)",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="h3" color={palette.secondary.main}>
              {title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {subTitle}
            </Typography>
          </CardContent>
          <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
            <IconButtonMedia iconName="spotify" link={spotify ? spotify : "www.google.com"} />
            <IconButtonMedia iconName="youtube" link={youTube ? youTube : "www.google.com"} />
            <IconButtonMedia iconName="apple" link={appleMusic ? appleMusic : "www.google.com"} />
          </Box>
        </Box>
        <CardMedia
          component="img"
          sx={{ width: 198, cursor: "pointer" }}
          image={image ? image : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}
          alt={alt ? alt : "song interview picture"}
          onClick={() => {
            navigate(`view-card/${_id}`);
          }}
        />
      </Card>
    </>
  );
}

export default SongCard;
