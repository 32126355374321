import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import NavTabs from "./NavTabs";
import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import AppTitle from "../AppTitle";
import DrawerTabs from "./DrawerTabs";
import "./../../App.css";

export default function Header() {
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const drawer = <DrawerTabs />;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" dir="rtl">
        <Toolbar>
          <AppTitle size={"34px"} />
          {mdAndUp ? (
            <div style={{ marginRight: "30%" }}>
              <NavTabs />
            </div>
          ) : (
            <>
              <Drawer
                anchor={"left"}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                PaperProps={{
                  sx: {
                    width: "45%",
                  },
                }}
              >
                {drawer}
              </Drawer>
            </>
          )}

          <Box sx={{ flexGrow: 1 }} />
          {!mdAndUp && (
            <IconButton
              onClick={() => setDrawerOpen(true)}
              size="large"
              edge="end"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
