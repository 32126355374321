interface Props {
  width?: string;
  height?: string;
  x?: string;
  y?: string;
}

function Spots({ width = "40px", height = "40px", x = "2px", y = "110px" }: Props) {
  return <div className="spots" style={{ width: width, height: height, left: x, top: y }}></div>;
}

export default Spots;
