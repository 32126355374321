import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header/Header";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import AboutPage from "./pages/AboutPage";
import ViewCardPage from "./pages/ViewCardPage";
// import PrimarySearchAppBar from "./components/Header/Header";
import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { verifyToken } from "./auth/TokenManager";
import React from "react";
import AppThemeProvider from "./plugins/mui";
import MySongsPage from "./pages/MySongsPage";

function App() {
  return (
    <>
      <CssBaseline />
      <AppThemeProvider>
        <Header />
        <ToastContainer position="top-right" theme="light" />
        {/* <Container fixed sx={{ margin: "10px auto" }}> */}
        {/* <Container sx={{ margin: "10px auto", minHeight: "700px" }}> */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/my-songs" element={<MySongsPage />} />
        </Routes>
        {/* </Container> */}
        {/* </Container> */}
        <Footer />
      </AppThemeProvider>
    </>
  );
}

export default App;
