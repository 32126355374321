import { Box, Typography } from "@mui/material";
import { palette } from "../../plugins/mui";

interface Props {
  mainText: string;
  subText?: string;
  textAlign?: string;
  color?: string;
}

function Title({ mainText, subText, textAlign = "center", color = palette.secondary.main }: Props) {
  return (
    <Box sx={{ width: "100%", textAlign: textAlign, marginBottom: "10px" }}>
      <Typography fontFamily={"sans-serif"} variant="h2" gutterBottom color={color}>
        {mainText}
      </Typography>
      {subText && (
        <Typography variant="h4" color={palette.secondary.main}>
          {subText}
        </Typography>
      )}
    </Box>
  );
}

export default Title;
