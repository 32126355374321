export const SONGS = [
  {
    title: "נולדת מחדש",
    subTitle: "שנינה-נוי",
    spotify: "https://open.spotify.com/track/4SPxNKeco45M8WEMdoBqb3?si=SAZIFDQmTw2Ll4T3D9iGfQ",
    youTube: "https://youtu.be/TCId076iaPg",
    appleMusic:
      "https://music.apple.com/il/album/%D7%A0%D7%95%D7%9C%D7%93%D7%AA-%D7%9E%D7%97%D7%93%D7%A9/1519656854?i=1519656859&l=he",
    id: "1",
    image: "",
  },

  {
    title: "נושמת",
    subTitle: "שנינה-נוי",
    spotify: "https://open.spotify.com/album/76r3Yz2r1K6Ff0I0lhHomd?si=w2nFhkB-TDW-7OqGrveGTg",
    youTube: "https://youtu.be/adQzukpwEvo",
    appleMusic:
      "https://music.apple.com/il/album/%D7%A0%D7%95%D7%A9%D7%9E%D7%AA-2022-remastered-version/1630093730?i=1630094085&l=he",
    id: "2",
    image: "",
  },
  {
    title: "מזמור לתודה",
    subTitle: "שנינה-נוי",
    spotify: "https://open.spotify.com/track/69bFwb2jZtvHwuLIDPtc8m?si=-UjsIyHUQsmh9UC-a0vyZQ",
    youTube: "https://youtu.be/sfAyfmy2YGE",
    appleMusic:
      "https://music.apple.com/il/album/%D7%9E%D7%96%D7%9E%D7%95%D7%A8-%D7%9C%D7%AA%D7%95%D7%93%D7%94/1564701665?i=1564702148&l=he",
    id: "3",
    image: "",
  },
];
