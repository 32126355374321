import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import PortraitIcon from "@mui/icons-material/Portrait";
import { Fab, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { palette } from "../plugins/mui";
import IconButtonMedia from "./general/IconButtonMedia";
import WhatsAppButton from "./general/WhatsAppButton";
console.error = () => {};

export default function LabelBottomNavigation() {
  const [value, setValue] = React.useState("recents");
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Fab
        sx={{
          backgroundColor: "green",
          position: "fixed",
          bottom: "10vh",
          left: "80%",
          width: "50px",
          height: "50px",
          border: "solid white 3px",
          "&:hover": {
            border: "solid black 3px",
          },
        }}
        size="small"
        aria-label="add"
      >
        <WhatsAppButton phoneNumber="972506899889"></WhatsAppButton>
      </Fab>

      <Paper
        sx={{
          color: "inherit",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          sx={{ width: 500, borderTopLeftRadius: "50px", borderTopRightRadius: "50px" }}
          value={value}
          onChange={handleChange}
        >
          <IconButtonMedia iconName="spotify" color="green" link={" www.google.com"} />
          <IconButtonMedia iconName="youtube" color="red" link={"www.google.com"} />
          <IconButtonMedia iconName="apple" link={"www.google.com"} />
        </BottomNavigation>
      </Paper>
    </>
  );
}
